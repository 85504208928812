<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 添加角色按钮 -->
      <el-row>
        <el-col>
          <el-button type="primary" @click="showAddDialog">添加角色</el-button>
        </el-col>
      </el-row>
      <!-- 角色列表 -->
      <el-table :data="rolesList" border stripe>
        <el-table-column label="角色id" prop="id"></el-table-column>
        <el-table-column label="角色名称" prop="role_name"></el-table-column>
        <el-table-column label="角色编码" prop="role_code"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeRoleById(scope.row.id)"
              >删除</el-button
            >
            <!-- <el-button
              type="warning"
              icon="el-icon-setting"
              size="mini"
              @click="showSetRightDialog(scope.row)"
            >分配权限</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加角色对话框 -->
    <el-dialog
      :title="isEdit ? '编辑角色' : '添加角色'"
      :visible.sync="roleDialogVisible"
      width="40%"
      @close="roleDialogVisibleClosed"
    >
      <el-form
        :model="roleForm"
        ref="roleFormRef"
        :rules="roleFormRules"
        label-width="100px"
      >
        <el-form-item label="角色名称">
          <el-input v-model="roleForm.role_name"></el-input>
        </el-form-item>
        <el-form-item label="角色编码">
          <el-input v-model="roleForm.role_code"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoles">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      // 所有角色列表
      rolesList: [],
      //   添加用户对话框
      roleDialogVisible: false,
      // 添加角色表单
      roleForm: {},
      //   添加角色表单验证
      roleFormRules: {
        role_name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        role_desc: [
          { required: true, message: "请输入角色描述", trigger: "blur" },
        ],
      },
      isEdit: false,
    };
  },
  created() {
    this.getRolesList();
  },
  methods: {
    async getRolesList() {
      const { data: res } = await this.$http.get("web_api/admin/role_list");
      if (res.code !== 200) {
        return this.$message.error("获取角色列表失败！");
      }
      this.rolesList = res.data;
    },
    // 添加角色对话框的关闭
    roleDialogClosed() {
      this.$refs.roleFormRef.resetFields();
    },
    // 添加角色
    addRoles() {
      this.$refs.roleFormRef.validate(async (valid) => {
        if (!valid) return;
        let params = _.cloneDeep(this.roleForm);
        let url;
        if (this.isEdit) {
          url = "web_api/admin/update_role";
        } else {
          url = "web_api/admin/add_role";
        }
        const { data: res } = await this.$http.post(url, params);
        if (res.code !== 200) {
          this.$message.error("添加角色失败！");
        }
        this.$message.success("添加角色成功！");
        this.roleDialogVisible = false;
        this.getRolesList();
      });
    },
    // 删除角色
    async removeRoleById(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该角色, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post("web—/" + id);
      if (res.meta.status !== 200) return this.$message.error("删除角色失败！");
      this.$message.success("删除用户成功！");
      this.getRolesList();
    },
    // 编辑角色
    async showAddDialog() {
      this.isEdit = false;
      this.roleForm = {};
      this.roleDialogVisible = true;
    },
    // 编辑角色
    async showEditDialog(row) {
      this.isEdit = true;
      this.roleForm.role_code = row.role_code;
      this.roleForm.id = row.id;
      this.roleForm.role_name = row.role_name;
      this.roleDialogVisible = true;
    }
  },
};
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
